.container {
    overflow-y: scroll;
}

.container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome browsers */
}

.container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* mozilla */
}